import { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import Footer from "./components/home/footer/Footer";
import Header from "./components/header/Header";



// src/pages/home & navlink 
const Home = lazy(() => import("./pages/home/Home"));
const About = lazy(() => import("./pages/about/About"));
const Portfolio = lazy(() => import("./pages/portfolio/Portfolio"));
const GetQuotation = lazy(() => import("./pages/quote/GetQuotation"));
const Purchase = lazy(() => import("./pages/purchase/Purchase"));
const Contact = lazy(() => import("./pages/contact/Contact"));

function App() {
  return (
    <>
      <Header />
      <Suspense fallback={'Loading...'}>
        <Routes>
          {/* src/pages/home & navlink */}
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/getQuotation" element={<GetQuotation />} />
          <Route path="/purchase/:id" element={<Purchase />} />
          <Route path="/purchase" element={<Purchase />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Suspense>
      <Footer />
    </>
  );
}

export default App;
