import React from 'react';
import styles from './Footer.module.css'; // Correctly import the CSS module
import '@fortawesome/fontawesome-free/css/all.min.css';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContainer}>
        {/* Company Section */}
        <div className={styles.footerSection}>
          <h4 className={styles.sectionTitle}>About PolyPortraits</h4>
          <p className={styles.companyDescription}>
            PolyPortraits is the leading brand for custom 3D portrait designs. With state-of-the-art technology,
            we craft lifelike and detailed portraits that capture every nuance, bringing your memories to life in a unique way.
          </p>

        </div>

        {/* Contact Section */}
        <div className={styles.footerSection}>
          <h4 className={styles.sectionTitle}>Contact Us</h4>
          <p className={styles.contactInfo}>PolyPortraits Headquarters</p>
          <p className={styles.contactInfo}>Dhaka, Bangladesh</p>
          <p className={styles.contactInfo}>Phone: +880 1995 571 712</p>
          <p className={styles.contactInfo}>
            Email: <a href="mailto:hello@polyportraits.com" className={styles.link}>hello@polyportraits.com</a>
          </p>
        </div>

        {/* Opening Hours Section */}
        <div className={styles.footerSection}>
          <h4 className={styles.sectionTitle}>Quick Links</h4>
          <ul className={styles.list}>
            <li><a href="/about" className={styles.link}>About Us</a></li>
            <li><a href="/getQuotation" className={styles.link}>Get Quotation</a></li>
            <li><a href="/purchase" className={styles.link}>Order</a></li>
            <li><a href="/portfolio" className={styles.link}>Our Portfolio</a></li>
            <li><a href="/contact" className={styles.link}>Contact Us</a></li>
          </ul>
        </div>

        {/* Newsletter Section */}
        <div className={styles.footerSection}>
          <h4 className={styles.sectionTitle}>Newsletter</h4>
          <p className={styles.newsletterInfo}>
            Stay updated with the latest trends in 3D portrait designs, exclusive offers, and insights from PolyPortraits.
            Sign up for our newsletter below.
          </p>
          <form className={styles.newsletterForm}>
            <input type="email" placeholder="Your email" className={styles.input} />
            <button type="submit" className={styles.button}>SIGN UP</button>
          </form>
        </div>
      </div>

      {/* Footer Bottom Section */}
      <div className={styles.footerBottom}>
        <p>© 2024 PolyPortraits, All Rights Reserved.</p>
        <ul className={styles.footerLinks}>
          <li><a href="https://pitech.com.bd/" target='_blank' rel="noreferrer" className={styles.link}>Technical Partnar: Progressive Information Technology (PiTech)</a></li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
